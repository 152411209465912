/** Authentication credentials */
export interface IAuthCredentials {
  /** User email */
  email: string;
  /** User password */
  password: string;
}

/** Authentication tokens */
export interface IAuthTokens {
  /** Refresh token */
  refreshToken: string;
  /** User role */
  role: string;
  /** Authentication JWT token */
  token: string;
  /** Authenticated user ID */
  userId: string;
}

/**  Forgot Password */
export interface IForgotPassword {
  /**  User email  */
  email: string;
}

/** Forgot Password reset request */
export interface IForgotPasswordRequestReset {
  /**  User code  */
  code: string;
}

/** Verification code type */
export enum VerificationCodeType {
  /** User verification (upon sign up) */
  ACCOUNT_VERIFICATION = 'account_verification',
  /** Email verification */
  EMAIL_VERIFICATION = 'email_verification',
  /** Reset password workflow */
  PASSWORD_RESET = 'password_reset'
}


import { Component, Vue } from 'vue-property-decorator';
import { AuthService, UserService } from '@/services';
import { UserModule } from '@/store/modules';
import { IAuthUser } from '@/types/user.types';
import { IForgotPassword, VerificationCodeType } from '@/types/auth.types';
import OnboardLayout from '@/components/OnboardLayout.vue';
import Title from '@/components/common/Title.vue';
@Component({
  components: { OnboardLayout, Title }
})
export default class EmailVerification extends Vue {
  button = false;
  sending = true;
  timerEnabled = false;
  timer = 60;
  forgot: IForgotPassword = { email: this.$route.query.email as string };
  /** If `email` is passed in the url query string, the user is in the
   * password reset flow. Otherwise, fallback to the account verification flow.
   */
  verificationType = this.forgot.email
    ? VerificationCodeType.PASSWORD_RESET
    : VerificationCodeType.ACCOUNT_VERIFICATION;
  showEditEmail = this.verificationType === VerificationCodeType.ACCOUNT_VERIFICATION;
  titleAndEmail =
    this.verificationType === VerificationCodeType.PASSWORD_RESET
      ? {
          title: 'Check your email',
          subtitle: 'We’ve sent you an email with the instructions to reset your password',
          email: this.forgot.email
        }
      : {
          title: 'Verify your email',
          subtitle: 'Please check your email for validating your email address',
          email: this.user?.email
        };
  /** Authenticated user */
  get user(): IAuthUser | null {
    return UserModule.authenticatedUser;
  }
  timerCountDown(): void {
    if (this.timer > 0) {
      this.timerEnabled = true;
      setTimeout(() => {
        this.timer--;
        this.timerCountDown();
      }, 1000);
    } else {
      this.timerEnabled = false;
      this.timer = 60;
    }
  }
  async resendEmail(): Promise<void> {
    try {
      if (this.timerEnabled) return;
      this.timerCountDown();
      this.verificationType === VerificationCodeType.PASSWORD_RESET
        ? await AuthService.forgot(this.forgot)
        : await AuthService.resendEmailVerify(this.user?.email);
    } catch (e: any) {
      this.$notifyError(this.$getError(e));
    }
  }
  async editEmail(): Promise<void> {
    try {
      this.$router.replace('/edit-email');
    } catch (e: any) {
      this.$notifyError(this.$getError(e));
    }
  }
  get image(): string {
    return require(`@/assets/images/opened-letter.png`);
  }
  async mounted(): Promise<void> {
    let fetchUser = await UserService.fetchUser();
    if (fetchUser.verifiedAt) {
      UserModule.setUser(fetchUser);
      window.location.replace('/login');
    }
  }
}
